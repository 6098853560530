:root {
  --color-darkest-green: #142e28;
  --color-dark-green: #1d453c;
  --color-medium-green: #335b56;
  --color-light-green: #3a6d62;
  --color-lighter-green: #c3d3cf;
  --color-wintermint: #f1fffa;
  --color-orange: #f47c3c;
  --color-light-orange: #ffb088; /*#ff9359;*/
  --color-lightest-orange: #f8f5f0;
  --color-brick: #d64045;
  --color-dark-brick: #923538;
  --color-blackish: #131e1b;
  --color-dark-gray: #2e2525;
  --color-darkest-gray: #0b0b0b;
  --color-medium-gray: #454545;
  --color-light-gray: #b0b5b3;

  --color-syntax-light-beige: #ddb18d;
  --color-syntax-light-gray: #98b0a9;
  --color-syntax-light-green: #75b372;
  --color-syntax-mid-green: #4a9246;
  --color-syntax-fern-green: #2f772b;
  --color-syntax-dark-beige: #8a7f6e;
  --color-syntax-dark-green: #101916;
  --color-syntax-dark-red: #b44e51;
  --color-syntax-beige: #b2998c;
  --color-syntax-charcoal: #818e9e;
  --color-syntax-steel-teal: #568684;
  --color-syntax-mid-blue: #4b83d4;
  --color-syntax-rose-vale: #ce6163;

  --body-background: white;
  --body-color: var(--color-dark-gray);
  --body-size: 120%;

  --color-code-background: var(--color-lightest-orange);

  --sl-font-sans: RedHatText, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  --display-sans: RedHatDisplay, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  --display-serif: DMSerif, Georgia, "Times New Roman", serif;

  --body-font-weight: 425;
  --body-line-height: 1.5;

  --sl-border-radius-medium: 0.35rem;

  --sl-color-primary-700: var(--color-dark-green);
  --sl-color-primary-600: var(--color-medium-green);
  --sl-color-primary-500: var(--color-light-green);

  --sl-font-weight-normal: var(--body-font-weight);

  --spacing-gigantic: 12rem;
  --max-content-width: 80rem;

  --h1-font-size: 3em;
  --h2-font-size: var(--h1-font-size);
  --h3-font-size: 1.6em;
  --h4-font-size: 1.3em;

  @media (--sl-breakpoint-xs) {
    --body-size: 115%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  18% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes swingBackAndForth {
  0% {
    transform: rotate(-7deg);
  }
  4.5% {
    transform: rotate(-16deg);
  }
  11.5% {
    transform: rotate(-7deg);
  }
  18% {
    transform: rotate(-10deg);
  }
  25% {
    transform: rotate(-7deg);
  }
  30% {
    transform: rotate(-8deg);
  }
  37% {
    transform: rotate(-7deg);
  }
}

*[hidden] {
  display: none;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: var(--body-size);
}

body {
  margin: 0;
  padding: 0;
  background: var(--body-background);
  color: var(--body-color);
  font-family: var(--sl-font-sans);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
}

h1,
h2,
h3,
h4,
h5 {
  font-family: var(--display-sans);
  font-weight: 900;
  letter-spacing: -0.01em;
  margin-top: 0;
  color: var(--color-medium-gray); /* var(--sl-color-neutral-600);*/
  outline: none;
  word-break: break-word;

  &.serif {
    font-family: var(--display-serif);
    font-weight: 400;
  }

  &.colorful {
    color: var(--color-dark-green);
  }
}

h1 {
  font-size: var(--h1-font-size);

  &.serif {
    font-size: calc(var(--h1-font-size) + 0.2em);
  }
}

h2 {
  font-size: var(--h2-font-size);

  &.serif {
    font-size: calc(var(--h2-font-size) + 0.2em);
  }

  @media (--sl-breakpoint-xs) {
    position: relative;
    overflow: hidden;
  }
}

h1,
h2 {
  line-height: 1.15;
  margin-bottom: 0.8em;
  text-wrap: balance;
}

h3 {
  font-size: var(--h3-font-size);
  line-height: 1.3;
  margin-bottom: 0.8em;

  &.serif {
    font-size: calc(var(--h3-font-size) + 0.2em);
  }
}

h4 {
  font-size: var(--h4-font-size);
  margin: 1.75em 0 1em;
}

h1,
h2,
footer {
  text-align: center;
}

p,
ul,
ol,
blockquote,
figure {
  margin-top: 0;
  margin-bottom: 1.3em;
  word-break: break-word;
}

a {
  color: var(--color-brick);
  text-underline-offset: 0.15em;
  text-decoration-color: var(--color-light-orange);
}

hr {
  border: none;
  border-top: 2px dashed var(--color-light-gray);
  margin: 2.5em auto;
}

abbr[title] {
  color: var(--color-medium-gray);
  font-weight: 600;
  cursor: help;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: var(--color-medium-gray);
  text-underline-offset: 0.2ch;
}

nav,
body > footer {
  padding-left: var(--sl-spacing-small);
  padding-right: var(--sl-spacing-small);
}

svg-wrapper,
nav sl-icon {
  transform: translate(0, 0); /* fix a Safari display bug with transitions */
}
svg-wrapper,
svg-wrapper svg {
  display: block;
}

.main-wave {
  background-color: white;
}

main {
  margin: 0 0 8rem;
  min-height: calc(100vh - 600px);
}

main-content {
  display: block;
}

section-wrapper {
  display: block;
  overflow: hidden;

  &[invert] {
    background: var(--color-blackish);
  }

  &[size="large"] {
    font-size: 115%;
    text-align: center;
  }
}

section-wrapper[invert],
footer {
  color: var(--color-wintermint);

  & h2 {
    color: var(--color-brick);
  }

  & a {
    color: var(--color-orange);
    text-decoration-color: var(--color-brick);
  }
}

section {
  padding: 2rem 0.75rem;
  margin: 0 auto;
  max-width: 65rem;

  @media (min-width: 500px) {
    padding: 2rem 3rem;
  }

  & ul,
  & ol {
    margin-left: var(--sl-spacing-x-large);
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;

    & > li {
      margin: 0 0 var(--sl-spacing-small);

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  & ul {
    list-style-type: square;
  }
}

.footer-wave {
  margin-bottom: -1px; /* needed to fix gap on Edge (Chrome?) */

  & svg {
    transform: scale(-1, 1);

    & path {
      fill: var(--color-dark-gray) !important;
    }
  }
}

footer {
  background: var(--color-dark-gray);
  color: var(--color-wintermint);
  padding: 3rem 20px;

  & h4 {
    color: white;
    margin-top: 1em;
  }

  & layout-columns {
    display: flex;
    text-align: left;
    justify-content: space-around;
    gap: 2rem;
    margin-bottom: 4rem;
    font-size: 90%;

    @media (min-width: 1200px) {
      font-size: calc(0.65rem + 0.4vw);
    }

    @media (--nav-minimal) {
      flex-direction: column;
      gap: 0;
    }
  }

  & layout-column {
    @media (--nav-fullsize) {
      padding-top: calc(0.875rem - 0.5vw);

      &:first-child {
        padding-top: 0;
      }
    }
  }

  & sl-button[size="small"] {
    --sl-button-font-size-small: 0.8em;
    --sl-input-height-small: calc(1.5em + 15px);
  }
}

html:not([loaded]) .fade-in-animation {
  opacity: 0;
  animation: fadeIn 0.8s 0.4s;
  animation-fill-mode: forwards;
}

.fade-in-always {
  opacity: 0;
  animation: fadeIn 0.8s;
  animation-fill-mode: forwards;
}

p.heading-icon {
  text-align: center;
  font-size: 250%;
  margin-bottom: 0;
  color: var(--sl-color-neutral-400);
}

section-wrapper[invert] p.heading-icon {
  color: var(--color-light-green);
}

bridgetown-search-results {
  font-weight: var(--body-font-weight);
  --link-color: var(--color-brick);

  &::part(inner) {
    border: 1px solid var(--color-code-background);
  }
}

svg.logo .letter {
  fill: #1e2826;
}

.version-text {
  color: var(--sl-color-neutral-300);
}

skip-to-main-content-bar {
  position: absolute;
  transform: translateY(-150px);
  padding: 0.7rem 0;
  background-color: var(--color-lightest-orange);
  display: flex;
  justify-content: center;

  &:focus-within {
    position: static;
    transform: initial;
  }
}
