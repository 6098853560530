@font-face {
  font-family: 'RedHatDisplay';
  src: url("../fonts/redhat/RedHatDisplayVFModified.woff2") format('woff2-variations');
  font-weight: 300 900;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'RedHatText';
  src: url("../fonts/redhat/RedHatTextVFModified.woff2") format('woff2-variations');
  font-weight: 400 900;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'RedHatText';
  src: url("../fonts/redhat/RedHatTextVF-ItalicModified.woff2") format('woff2-variations');
  font-weight: 400 900;
  font-style: italic;
  font-display: fallback;
}
