body.docs > main > aside {
  & nav {
    margin-top: 4.2em;

    @media (--nav-minimal) {
      margin-top: 2em;
      border-top: 2px solid var(--color-code-background);
    }
  }

  & ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;

    & li {
      margin-bottom: 0.4em;
    }
  }

  --h3-font-size: 1.3em;

  & h3 {
    margin-top: 1.5em;
    margin-bottom: 0.8em;
    text-align: left;
  }

  & a[aria-current] {
    font-weight: bold;
  }
}
